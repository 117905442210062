<template>
  <div class="container-fluid">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="px-4 pt-2 pb-5">
      <div class="bg p-3">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des clients API</h4>
          </div>
          <form class="d-flex my-1 me-2">
            <div class="input-group me-4">
              <input
                v-model="mxSearch"
                type="text"
                class="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-query"
              />
              <button type="submit" class="style_btn btn btn-primary" id="button-query">
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form>
          <div class="dropdown me-3">
            <button
              :disabled="selected.length == 0"
              class="btn style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action des comptes
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li class="border">
                <a class="dropdown-item" @click="activerCompte" href="#">Activer</a>
                <a class="dropdown-item" @click="desactiverCompte(false)" href="#"
                  >Désactiver</a
                >
                <a class="dropdown-item" @click="reinitializerCompte" href="#"
                  >Réinitialiser</a
                >
              </li>
              <li class="border">
                <a class="dropdown-item" @click="reinitializerCompte" href="#"
                  >Envoyer mail</a
                >
              </li>
            </ul>
          </div>
          <api-client-edit
            v-if="$can('CREATE', 'AGENT')"
            ref="apiClientEditor"
            @saved="updateOrCreateApiClient"
            btnText="Créer un client api"
            :item="activeApiClient"
            title="Ajouter un client api"
          />
        </div>
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              :sort-by.sync="mxPagination.sortBy"
              :sort-desc.sync="mxPagination.sortDesc"
              responsive="sm"
              selectable
              sort-icon-left
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>

              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(enabled)="data">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    v-model="data.item.enabled"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="data.item.enabled"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{{
                    data.item.enabled ? "Actif" : "Inactif"
                  }}</label>
                </div>
              </template>
              <template #cell(apiToken)="data">
                <span v-tooltip.top>{{ data.item.apiToken | truncate(20) }}</span>
                <button
                  class="btn btn-sm"
                  @click.prevent.stop="copyToClipboard(data.item.apiToken)"
                >
                  <i class="bi bi-clipboard"></i>
                </button>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a
                    @click.prevent="editApiClient(data.item)"
                    class="btn btn-success rounded-0 btn-sm me-1"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <a class="btn btn-info rounded-0 btn-sm me-1"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    @click.prevent="destroyApiClient(data.item)"
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    ><i class="bi bi-trash"></i
                  ></a>
                  <!-- <button type="button" class="btn btn-outline-secondary">Réinitialiser</button> -->
                  <button
                    type="button"
                    class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="visually">Compte</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a @click="activerCompte" class="dropdown-item" href="#">Activer</a>
                    </li>
                    <li>
                      <a @click="desactiverCompte" class="dropdown-item" href="#"
                        >Désactiver</a
                      >
                    </li>
                    <li>
                      <a @click="reinitializerCompte" class="dropdown-item" href="#"
                        >Réintialiser</a
                      >
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li><a class="dropdown-item" href="#">Envoyer mail</a></li>
                  </ul>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ (mxPagination.page - 1) * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import ApiClientEdit from "../../../components/espace/admin/api-client/ApiClientEdit.vue";

export default {
  components: {
    ApiClientEdit,
    // ApiClientEdit
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      filterOptions: [
        // {
        //   column: 'departement',
        //   value: '',
        //   label: 'Département',
        //   items: []
        // },
        // {
        //   column: 'commune',
        //   value: '',
        //   label: 'Commune',
        //   items: []
        // },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        // _queryOptions: {
        //   departement: 'departement',
        //   skills: 'skills__in',
        //   languages: 'languages__in',
        //   availabilities: 'availability__in',
        //   level: 'level',
        //   price_min: 'budget__gte',
        //   price_max: 'budget__lte'
        // },
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeApiClient: {
        nom: null,
        prenom: null,
        age: null,
        matricule: null,
        phone: null,
        phoneAlternatif: null,
        organisme: null,
        typeOrganisme: null,
        fonction: null,
      },
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "ApiClients",
          active: true,
        },
      ],
    };
  },
  created() {
    this.mxInitializePaginator({
      queryModel: "apiClient",
      search: this.search,
      fetcherMethod: "apiClient/fetchApiClients",
      dataGetter: "apiClient/apiClients",
      paginationGetter: "apiClient/pagination",
      autoSubmitQuery: false,
      pagination: {
        sortBy: "nom",
        sortDesc: false,
        descending: false,
        page: 0,
        rowsPerPage: 20,
      },
      filterOptions: this.filterOptions,
      searchFields: ["search"],
    });
  },
  computed: {
    ...mapGetters({
      pagination: "apiClient/pagination",
    }),
    tableFields() {
      return [
        "#",
        "index",
        // { key: 'matricule', label: 'Matricule' },
        { key: "intitule", label: "Nom client" },
        { key: "phone", label: "Téléphone" },
        { key: "email", label: "Email" },
        { key: "apiToken", label: "Token" },
        { key: "apiKey", label: "Clé" },
        // { key: "enabled", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateApiClient: "apiClient/createOrUpdateApiClient",
      resetApiClientAccount: "apiClient/resetApiClientAccount",
      deleteApiClient: "apiClient/deleteApiClient",
    }),
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast.info("Token copié!", {
        position: "top-right",
        duration: 5000,
      });
    },
    reinitializerCompte() {
      this.resetApiClientAccount(this.selected).then(() => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
    desactiverCompte() {},
    activerCompte() {},
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    editApiClient(apiClient) {
      console.log(apiClient);
      this.activeApiClient = { ...apiClient };
      this.$refs["apiClientEditor"].show();
      // this.$router.push({ name: 'espace.admin.apiClients.edit' })
    },
    async destroyApiClient(apiClient) {
      await this.$dialog.confirm({
        size: "md",
        centered: true,
        variant: "success",

        text: "Voulez-vous vraiment supprimer cet élément?",
        actions: {
          false: {
            text: "Non",
            variant: "outline-success",
          },
          true: {
            text: "Oui",
            variant: "success",
            handle: async () => {
              this.deleteApiClient(apiClient);
            },
          },
        },
      });
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    updateOrCreateApiClient(apiClient) {
      console.log(apiClient);
      this.createOrUpdateApiClient(apiClient)
        .then((data) => {
          console.log(data);
          this.$toast.success("Opération réussie!", {
            position: "top-right",
            duration: 5000,
          });
        })
        .catch((err) => {
          // console.log(data)
          this.$toast.error(err.message, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style></style>
