<template>
  <div>
    <slot name="action">
      <a href="#" class="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Ajouter</a>
    </slot>
    <b-modal
      id="modal-footer-sm"
      ref="modal"
      size="lg"
      hide-footer
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <template #modal-header>
        <h5 class="modal-title" id="staticBackdropLabel">AGENT</h5>
        <button
          type="button"
          class="style_btn btn-close"
          data-bs-dismiss="modal"
          @click.prevent="cancel"
          aria-label="Close"
        ></button>
      </template>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <h5 class="mt-2">Etat civil</h5>
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.intitule"
                    @input="handleInput"
                    type="text"
                    class="form-control"
                    id="intitule"
                    name="intitule"
                    placeholder="intitule"
                  />
                  <label for="intitule">Nom du client</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.matricule"
                    @input="handleInput"
                    type="text"
                    class="form-control"
                    id="matricule"
                    name="matricule"
                    placeholder="Matricule"
                  />
                  <label for="matricule">Matricule</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <h5>Adresse et contacts</h5>
          <div class="row">
            <div class="col-sm-6">
              <ValidationProvider
                :rules="`required|${editableItem.id == null ? 'available:EMAIL' : ''}`"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.email"
                    @input="handleInput"
                    type="text"
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                  />
                  <label for="email">Email</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider
                :rules="`required|${
                  editableItem.id == null ? 'available:PHONE|' : ''
                }exact:8`"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.phone"
                    @input="handleInput"
                    type="text"
                    class="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Téléphone"
                  />
                  <label for="phone">Téléphone</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.adresse"
                    @input="handleInput"
                    type="text"
                    class="form-control"
                    id="adresse"
                    name="adresse"
                    placeholder="Adresse"
                  />
                  <label for="adresse">Adresse</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.codePostal"
                    type="text"
                    class="form-control"
                    id="codePostal"
                    name="codePostal"
                    placeholder="Code postal"
                  />
                  <label for="codePostal">Code Postal</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form- mb-3">
                  <label for="nom">Roles</label>
                  <multiselect
                    v-model="editableItem.roles"
                    :options="roles"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Roles"
                    label="name"
                    track-by="id"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableItem: {},
    };
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  created() {
    this.fetchRoles({
      pageSize: 200,
    });
  },
  computed: {
    ...mapGetters({
      roles: "role/roles",
    }),
  },
  methods: {
    ...mapActions({
      fetchRoles: "role/fetchRoles",
    }),
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>
